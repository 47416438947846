import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { Role } from '../hooks/useAuth/Auth.provider';
import { PASSCODE_RESTRICTED, PASSCODE_FULL } from './constants';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { url } from 'inspector';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD0oVINz6xKKgHQ0sDf3JkrxU2TrMIfx3Q',
  authDomain: 'investment-data-vimbu.firebaseapp.com',
  projectId: 'investment-data-vimbu',
  storageBucket: 'investment-data-vimbu.appspot.com',
  messagingSenderId: '773584552700',
  appId: '1:773584552700:web:608037f55fdfcdd5ec8d79',
  measurementId: 'G-S2K78P87SQ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (password: string) => {
  try {
    await signInWithEmailAndPassword(auth, PASSCODE_RESTRICTED || '', password);
    return Role.PUBLIC;
  } catch (err: any) {
    try {
      await signInWithEmailAndPassword(auth, PASSCODE_FULL || '', password);
      return Role.PRIVATE;
    } catch (err: any) {
      throw new Error(err.message);
    }
  }
};

// const analytics = getAnalytics(app);
const storage = getStorage(app);

const downloadFile = async (fileName: string) => {
  try {
    const url = await getDownloadURL(ref(storage, fileName));

    return url;
  } catch (error) {
    // Handle any errors
    alert(error);
  }
};

export { logInWithEmailAndPassword, downloadFile };
