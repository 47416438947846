/* eslint-disable max-len */
import React from 'react';
import './burgerButton.scss';

interface Props {
  isCross: boolean;
  onClick: () => void;
}

const BurgerButton: React.FC<Props> = ({ isCross, onClick }): JSX.Element => {
  return (
    <button onClick={onClick} className="burger-button">
      {isCross ? (
        <svg
          version="1.1"
          id="cross-svg"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 40 40"
        >
          <path
            d="M32.34,34.14L5.86,7.66c-0.5-0.5-0.5-1.3,0-1.8l0,0c0.5-0.5,1.3-0.5,1.8,0l26.49,26.49c0.5,0.5,0.5,1.3,0,1.8l0,0
                C33.65,34.64,32.84,34.64,32.34,34.14z"
          />
          <path
            d="M34.14,7.66L7.66,34.14c-0.5,0.5-1.3,0.5-1.8,0h0c-0.5-0.5-0.5-1.3,0-1.8L32.34,5.86c0.5-0.5,1.3-0.5,1.8,0v0
                C34.64,6.35,34.64,7.16,34.14,7.66z"
          />
        </svg>
      ) : (
        <svg
          width="58"
          height="24"
          viewBox="0 0 58 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="58" height="3.86667" rx="1.93333" fill="white" />
          <rect
            y="9.66699"
            width="40.6"
            height="3.86667"
            rx="1.93333"
            fill="white"
          />
          <rect
            y="19.333"
            width="30.9333"
            height="3.86667"
            rx="1.93333"
            fill="white"
          />
        </svg>
      )}
    </button>
  );
};

export default BurgerButton;
