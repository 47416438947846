/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect, Dispatch } from 'react';

export const useLocalStorage = (
  key: string,
  defaultValue: string
): [string, Dispatch<any>, (value: string) => void] => {
  return useStorage(key, defaultValue, window.localStorage);
};
export const useSessionStorage = (
  key: string,
  defaultValue: string
): [string, Dispatch<any>, (value: string) => void] => {
  return useStorage(key, defaultValue, window.sessionStorage);
};

/*
 useStorage hook
    export default function StorageComponent() {
  const [name, setName, removeName] = useSessionStorage("name", "Kyle")
  const [age, setAge, removeAge] = useLocalStorage("age", 26)

  return (
    <div>
      <div>
        {name} - {age}
      </div>
      <button onClick={() => setName("John")}>Set Name</button>
      <button onClick={() => setAge(40)}>Set Age</button>
      <button onClick={removeName}>Remove Name</button>
      <button onClick={removeAge}>Remove Age</button>
    </div>
  )
}
 */

const useStorage = (
  key: string,
  defaultValue: string,
  storageObject: Storage
): [string, Dispatch<any>, (value: string) => void] => {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue !== null) return JSON.parse(jsonValue);
    return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
};
