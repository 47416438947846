import { toast } from 'react-toastify';

type ToastType = 'info' | 'error' | 'success' | 'warning';

const toastMessage = (message: string, type: ToastType) => {
  switch (type) {
    case 'info':
      return toast.info(message);
    case 'error':
      return toast.error(message);
    case 'success':
      return toast.success(message);
    case 'warning':
      return toast.warn(message);
    default:
      return toast.info(message);
  }
};

export default toastMessage;
