import React, { createContext, useContext } from 'react';
import useTranslation, { EN } from './useTranslation.hook';

export interface I18NContextInterface {
  language: string;
  setLanguage: (lang: string) => void;
  fallbackLanguage: string;
  setFallbackLanguage: (lang: string) => void;
  t: (translationString: string) => string;
}

const defaultI18NContext = {
  language: EN,
  setLanguage: (lang: string) => lang,
  fallbackLanguage: EN,
  setFallbackLanguage: (lang: string) => lang,
  t: (lang: string) => lang
};

const I18NContext = createContext<I18NContextInterface>(defaultI18NContext);

export const useI18NContext = (): I18NContextInterface => {
  return useContext<I18NContextInterface>(I18NContext);
};

const I18NProvider: React.FC = ({ children }): JSX.Element => {
  const value = useTranslation();

  return <I18NContext.Provider value={value}>{children}</I18NContext.Provider>;
};

export default I18NProvider;
