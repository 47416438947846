import React, { createContext, useContext, useState } from 'react';
import useAuth from './useAuth.hook';

export enum Role {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export interface AuthInterface {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  role: Role;
  setRole: (role: Role) => void;
}

const defaultAuthContext = {
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => isLoggedIn,
  role: Role.PUBLIC,
  setRole: (role: Role) => role
};

const AuthContext = createContext<AuthInterface>(defaultAuthContext);

export const useAuthContext = (): AuthInterface => {
  return useContext<AuthInterface>(AuthContext);
};

const AuthProvider: React.FC = ({ children }): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(Role.PUBLIC);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        role,
        setRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
