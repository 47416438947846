import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from '../svg/logo.tsx';
import './Header.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ABOUT, HOME, INSTRUCTORS, JOBS, routes } from '../../config/routes';
import BurgerButton from '../svg/burgerButton/burgerButton';
import LangSelector from './LangSelector/LangSelector';
import { useI18NContext } from '../../hooks/useTranslations/Translation.provider';

const Header: React.FC = (): JSX.Element => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [hasBackground, setHasBackground] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const { t } = useI18NContext();
  const { pathname } = useLocation();

  const toggleMobileMenu = (toggle?: boolean) => {
    setIsMobileMenuOpen(toggle !== undefined ? toggle : !isMobileMenuOpen);
  };

  useEffect(() => {
    const onScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    if (scrollTop > 10 && !hasBackground) {
      setHasBackground(true);
    } else if (scrollTop < 10 && hasBackground) {
      setHasBackground(false);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scrollTop, hasBackground, pathname]);

  return (
    <header
      className={`app-header-default ${hasBackground ? '' : 'is-transparent'}`}
    >
      <a href="https://vimbu.app">
        <Logo />
      </a>
      <BurgerButton isCross={false} onClick={() => toggleMobileMenu()} />
      <nav className={`${isMobileMenuOpen ? 'is-open' : ''}`}>
        <BurgerButton isCross={true} onClick={() => toggleMobileMenu()} />
        <ul>
          <li>
            <a
              href={'https://vimbu.app'}
              onClick={() => toggleMobileMenu(false)}
              // activeClassName="isActive"
            >
              Back to Home
            </a>
          </li>
          <li>
            <a
              href={'https://vimbu.app/contact'}
              onClick={() => toggleMobileMenu(false)}
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href={'https://vimbu.app/download'}
              onClick={() => toggleMobileMenu(false)}
            >
              Download App
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
