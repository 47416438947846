/* eslint-disable max-len */
import React from 'react';

const Logo: React.FC = (): JSX.Element => {
  return (
    <svg
      width="262"
      height="69"
      viewBox="0 0 262 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
    >
      <path
        d="M60.8454 6.35526H46.3219L33.6537 41.0498L30.3907 50.7773L27.0637 41.0498L14.5236 6.35526H0L24.6325 68.0921H36.2129L60.8454 6.35526ZM65.8999 14.3318C65.8999 18.6767 69.4188 22.3083 73.8335 22.3083C78.1201 22.3083 81.7031 18.6767 81.7031 14.3318C81.7031 9.922 78.1201 6.35526 73.8335 6.35526C69.4188 6.35526 65.8999 9.922 65.8999 14.3318ZM80.5514 68.0921V28.0799H67.1155V68.0921H80.5514ZM131.608 47.7293C131.608 43.1898 133.655 38.1316 138.838 38.1316C143.316 38.1316 144.596 42.0226 144.596 45.9135V68.0921H158.032V45.9135C158.032 35.6024 153.105 27.172 142.804 27.172C136.598 27.172 132.184 28.9878 128.345 33.9812C125.594 29.4417 121.115 27.172 116.061 27.172C111.71 27.172 107.871 29.5066 105.184 32.9436V28.0799H91.748V68.0921H105.184V48.1184C105.184 44.0978 107.871 38.1316 112.414 38.1316C116.892 38.1316 118.172 42.0226 118.172 45.9135V68.0921H131.608V47.7293ZM180.873 48.1184C180.873 42.8656 184.904 38.1316 190.278 38.1316C195.652 38.1316 199.683 42.8656 199.683 48.1184C199.683 53.3712 195.652 58.1053 190.278 58.1053C184.904 58.1053 180.873 53.3712 180.873 48.1184ZM180.873 68.0921V63.2932C183.56 67.3788 189.254 69 194.373 69C205.569 69 214.015 59.2077 214.015 48.1184C214.015 37.0291 205.569 27.172 194.373 27.172C189.254 27.172 184.328 28.5987 180.873 32.9436V0H167.437V68.0921H180.873ZM221.628 50.8421C221.628 61.672 225.915 69 236.472 69C241.654 69 245.877 66.6654 248.564 63.1635V68.0921H262V28.0799H248.564V48.1184C248.564 53.5009 245.237 58.1053 241.014 58.1053C236.856 58.1053 235.064 55.4464 235.064 50.8421V28.0799H221.628V50.8421Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
