/* eslint-disable @typescript-eslint/ban-ts-comment,@typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable
} from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import toastMessage from '../utils/toast';
import {
  DEFAULT_ERROR_MESSAGE,
  REACT_APP_BACKEND_URL,
  REACT_APP_CHAT_API_URL_HTTP,
  REACT_APP_CHAT_API_URL_WS
} from './constants';

export const CHAT_API_NAME = 'CHAT_API_NAME';
export const WS_CHAT_API_NAME = 'WS_CHAT_API_NAME';

type graphQlError = {
  message: string;
};
const onApolloClientError = (
  error: ErrorHandler & { graphQLErrors: [graphQlError] }
) => {
  if (error && error.graphQLErrors) {
    if (error.graphQLErrors[0]?.message.toLowerCase() === 'not authorized')
      return;
    toastMessage(
      error.graphQLErrors[0]?.message || DEFAULT_ERROR_MESSAGE,
      'error'
    );
  }
};

const getAccessToken = async () => {
  try {
    // return await auth.currentUser?.getIdToken();
    return '';
  } catch (error) {
    return Promise.reject(error);
  }
};

const getApolloClient = async (): Promise<ApolloClient<any>> => {
  const cache = new InMemoryCache({});
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then(async () => {
            const accessToken = await getAccessToken();
            if (accessToken) {
              operation.setContext({
                headers: {
                  authorization: `bearer ${accessToken}`
                }
              });
            }
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  return new ApolloClient<any>({
    link: ApolloLink.split(
      (operation) => operation.getContext().apiName === WS_CHAT_API_NAME,
      ApolloLink.from([
        // @ts-ignore
        onError(onApolloClientError),
        new WebSocketLink({
          uri: REACT_APP_CHAT_API_URL_WS,
          options: {
            lazy: true,
            reconnect: true,
            connectionParams: async () => {
              const token = await getAccessToken();
              return {
                headers: {
                  authorization: token ? `Bearer ${token}` : ''
                }
              };
            }
          }
        })
      ]),
      ApolloLink.split(
        (operation) => operation.getContext().apiName === CHAT_API_NAME,
        ApolloLink.from([
          // @ts-ignore
          onError(onApolloClientError),
          requestLink,
          // @ts-ignore
          createUploadLink({
            uri: REACT_APP_CHAT_API_URL_HTTP
          })
        ]),
        ApolloLink.from([
          // @ts-ignore
          onError(onApolloClientError),
          requestLink,
          // @ts-ignore
          createUploadLink({
            uri: REACT_APP_BACKEND_URL
          })
        ])
      )
    ),
    cache
  });
};

export default getApolloClient;
