import { useLocalStorage } from '../useStorage/useStorage.hook';
import * as translations from './translations';

export const EN = 'EN';
export const EN_CODE = 'en-US';
export const FR = 'FR';
export const FR_CODE = 'fr-FR';
export const ES = 'ES';
export const ES_CODE = 'es-ES';
export const LANGUAGE_KEY = 'language';
export const FALLBACK_LANGUAGE_KEY = 'fallbackLanguage';

export interface LanguageInterface {
  name: string;
  shortcut: string;
  code: string;
  value: string;
}

export const languages: LanguageInterface[] = [
  {
    name: 'english',
    shortcut: 'En',
    code: EN_CODE,
    value: EN
  },
  {
    name: 'french',
    shortcut: 'Fr',
    code: FR_CODE,
    value: FR
  },
  {
    name: 'spanish',
    shortcut: 'Es',
    code: ES_CODE,
    value: ES
  }
];

const getBrowserLanguage = (): string => {
  const browserL: string = (navigator.language || EN).toLowerCase();
  const lang = languages.find((el) => {
    return (
      el.value.toLowerCase() === browserL || el.code.toLowerCase() === browserL
    );
  });
  return lang?.value || EN;
};

export interface useTranslationInterface {
  language: string;
  setLanguage: (lang: string) => void;
  fallbackLanguage: string;
  setFallbackLanguage: (lang: string) => void;
  t: (lang: string) => string;
}

const useTranslation = (): useTranslationInterface => {
  const [language, setLanguage] = useLocalStorage(
    LANGUAGE_KEY,
    getBrowserLanguage()
  );
  const [fallbackLanguage, setFallbackLanguage] = useLocalStorage(
    FALLBACK_LANGUAGE_KEY,
    EN
  );

  const translate = (key: string) => {
    const keys = key.split('.');

    return (
      getNestedTranslation(language, keys) ??
      getNestedTranslation(fallbackLanguage, keys) ??
      key
    );
  };

  return {
    language,
    setLanguage,
    fallbackLanguage,
    setFallbackLanguage,
    t: translate
  };
};

function getNestedTranslation(language: string, keys: string[]) {
  return keys.reduce((obj, key) => {
    return obj?.[key];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, translations[language]);
}

export default useTranslation;
