/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './App.scss';
import Header from '../Header/Header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { REACT_APP_ENVIRONMENT, ENVIRONMENTS } from '../../config/constants';
import Footer from '../Footer/Footer';
import I18NProvider from '../../hooks/useTranslations/Translation.provider';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import getApolloClient from '../../config/apolloClient';
import GoogleAnalytics from '../GoogleAnalytics/GoogleAnalytics';
import Fundraising from '../pages/Fundraising/Fundraising';
import Auth from '../pages/Auth/Auth';
import AuthProvider, {
  useAuthContext
} from '../../hooks/useAuth/Auth.provider';
import ProtectedRoute from '../../ProtectedRoute/ProtectedRoute';

const App = (): JSX.Element => {
  const [client, setClient] = React.useState<ApolloClient<any> | null>(null);
  const { isLoggedIn, setIsLoggedIn } = useAuthContext();

  React.useEffect(() => {
    const asyncSetClient = async () => {
      const c = await getApolloClient();
      setClient(c);
    };

    asyncSetClient();
  }, []);

  useEffect(() => {
  }, [isLoggedIn]);

  if (!client) {
    return <div>Loading</div>;
  }



  return (
    <div className="App">
      {/* If production add google tags */}
      <GoogleAnalytics />
      <ApolloProvider client={client}>
        <I18NProvider>
          <Router>
            <Switch>
              <Route path="/login" component={() => <Auth />} />
              <ProtectedRoute>
                <Route
                  path="/documents"
                  component={() => (
                    <>
                      <Header />
                      <Fundraising />
                    </>
                  )}
                />
              </ProtectedRoute>
              <Redirect to="/login" />
            </Switch>
          </Router>
        </I18NProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
