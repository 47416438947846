/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import './Fundraising.scss';
import { useI18NContext } from '../../../hooks/useTranslations/Translation.provider';
import { Router } from 'react-router-dom';
import image from '../../../img/ski_image.png';
import SkiAnimation from '../../SkiAnimation/SkiAnimation';
import ReactModal from 'react-modal';
import { downloadFile } from '../../../config/firebase';
import files from '../../../files.json';
import { Role, useAuthContext } from '../../../hooks/useAuth/Auth.provider';
import { triggerGoogleTagEvent } from '../../../utils/triggerGoogleTagEvent';
import { ReactComponent as Close } from '../../../components/svg/icons/close.svg';

interface Props {
  locale: string;
}

interface CardProps {
  item: any;
  onClick: (item: any) => void;
}

const Card = ({ item, onClick }: CardProps) => {
  const { t } = useI18NContext();
  const { role } = useAuthContext();

  return (
    <div
      className={'items-card'}
      onClick={() => {
        onClick(item);
      }}
    >
      <h3 className={'card-title'}>{item.name}</h3>
      <div className={'divider'} />
      <div className={'card-description'}>
        <ul>
          {item.files.map((file: any) => (
            <li>{file.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Fundraising: React.FC = (): JSX.Element => {
  const [category, setcategory] = useState<string>('all');
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [item, setItem] = useState<any>(null);
  const [fileUrls, setFileUrls] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { role } = useAuthContext();

  useEffect(() => {
    const fetchUrls = async () => {
      const files = await Promise.all(
        item.files.map(async (file: any) => {
          if (file.access === 'private' && role === 'PUBLIC') {
            return { ...file, url: null };
          } else if (file.name === 'Deck') {
            return file;
          } else if (file.name === 'Ski Game :)') {
            return { ...file, url: 'https://vimbu-ski-game.netlify.app' };
          } else {
            const downloadUrl = await downloadFile(file.url);
            return { ...file, url: downloadUrl };
          }
        })
      );
      return files;
    };
    if (item) {
      setLoading(true);
      fetchUrls().then((files) => {
        setFileUrls(files);
        setLoading(false);
      });
    }
  }, [item]);

  const categories = files.categories;

  const items = files.folders;

  const handlecategoryChange = (e: any) => {
    setcategory(e.target.value);
  };

  const handleitemselection = (item: any) => {
    triggerGoogleTagEvent('select-folder', { folder: item.name });
    setModalOpen(true);
    setItem(item);
  };

  const renderitemsList = () => {
    if (category === 'all' && items.length > 0) {
      return (
        <>
          <div className={'items-cards'}>
            {items
              // .filter((item: any) => item.category === category.slug)
              .map((item: any) => (
                <Card item={item} onClick={handleitemselection} />
              ))}
          </div>
        </>
      );
    }

    if (items.filter((item: any) => item.category === category).length > 0) {
      return (
        <div className={'items-cards'}>
          {items
            .filter((item: any) => item.category === category)
            .map((item: any) => (
              <Card item={item} onClick={handleitemselection} />
            ))}
        </div>
      );
    } else {
      return (
        <div className={'no-results'}>
          <p>There are no folders available for that category</p>
        </div>
      );
    }
  };

  return (
    <div className={'fundraising-container'}>
      <div className={'content'}>
        <div className={'image-container'}>
          <img src={image} />
        </div>
        <div className={'items-container'}>
          <div className={'items-header'}>
            <h1 className={'items-title'}>Investor Data Room</h1>
            <div className={'dropdown'}>
              <label>Type</label>
              <select value={category} onChange={handlecategoryChange}>
                <option value="all">All</option>
                <option value="General">General</option>
                <option value="Financials">Financials</option>
                <option value="Previous Round">Previous Round</option>
                <option value="Market Research">Market Research</option>
              </select>
            </div>
          </div>
          <div className={'items-list'}>{renderitemsList()}</div>
          <ReactModal
            isOpen={openModal}
            ariaHideApp={false}
            onRequestClose={() => {
              setModalOpen(false);
              setItem(null);
              setFileUrls([]);
            }}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 20
              },
              content: {
                zIndex: 20,
                position: 'absolute',
                top: '10vh',
                left: '10vw',
                right: '10vw',
                bottom: '10vh',
                border: 'none',
                background: '#fff',
                boxShadow: '0px 4px 10px rgba(19, 158, 238, 0.09)',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '20px'
              }
            }}
          >
            <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
              <Close onClick={() => setModalOpen(false)} />
            </div>
            {item && fileUrls && (
              <div className="modal">
                <h1 className="modal-title">{item.name}</h1>
                <hr />
                <div className="download-list">
                  {loading ? <p>Loading files...</p> : null}
                  <ul>
                    {fileUrls.map((file: any) => (
                      <li>
                        {file.url ? (
                          <a
                            target="_blank"
                            href={file.url}
                            onClick={() => {
                              triggerGoogleTagEvent('download-file', {
                                fileName: file.name
                              });
                            }}
                          >
                            {file.name}
                          </a>
                        ) : (
                          <p className="private-file">{file.name}</p>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </ReactModal>
        </div>
      </div>
      <SkiAnimation />
    </div>
  );
};

export default Fundraising;
