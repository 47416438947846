import { Helmet } from 'react-helmet-async';
import { REACT_APP_GA4_ID, REACT_APP_GTM_ID } from '../../config/constants';

// JSX interploated version of the script tags used for Google Analytics, rendering adds them to head
const GoogleAnalytics: React.FC = (): JSX.Element => {
  return (
    <Helmet>
      <script>
        {`
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${REACT_APP_GTM_ID}');`}
      </script>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA4_ID}`}
      ></script>
      <script>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${REACT_APP_GA4_ID}');`}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
