import React, { ButtonHTMLAttributes } from 'react';
import './Button.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  theme?: 'light' | 'primary';
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  classNames?: string[];
};
const Button = ({
  title,
  theme = 'primary',
  Icon,
  classNames = [],
  ...props
}: Props) => (
  <button
    type="submit"
    {...props}
    className={`custom-button ${theme} ${classNames.join(' ')}`}
  >
    <span> {title}</span>
    {Icon && <Icon />}
  </button>
);

export default Button;
