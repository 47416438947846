import { Redirect } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuth/Auth.provider';

const ProtectedRoute = ({ children }: any) => {
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
