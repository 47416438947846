import { ReactComponent as SkiIcon } from '../components/svg/icons/ski-32.svg';
import { ReactComponent as SnowboardIcon } from '../components/svg/icons/snowboard.svg';
import { ReactComponent as NewIcon } from '../components/svg/icons/new.svg';
import { ReactComponent as IntermediateIcon } from '../components/svg/icons/intermediate.svg';
import { ReactComponent as AdvancedIcon } from '../components/svg/icons/advanced.svg';
import { ReactComponent as UKFlag } from '../components/svg/flags/english_uk.svg';
import { ReactComponent as FranceFlag } from '../components/svg/flags/french_fr.svg';
import { ReactComponent as SpainFlag } from '../components/svg/flags/spanish_es.svg';
import { ReactComponent as ItalianFlag } from '../components/svg/flags/italian_it.svg';
import { ReactComponent as CatalanFlag } from '../components/svg/flags/spanish_ct.svg';
import { ReactComponent as GermanFlag } from '../components/svg/flags/german.svg';
import { ReactComponent as ArabicFlag } from '../components/svg/flags/arabic_sa.svg';
import { ReactComponent as DutchFlag } from '../components/svg/flags/dutch.svg';
import { ReactComponent as RussianFlag } from '../components/svg/flags/russian.svg';
import { ReactComponent as PortugueseFlag } from '../components/svg/flags/portuguese_pt.svg';
import { ReactComponent as WorldFlag } from '../components/svg/flags/world.svg';

export const isProduction = process.env.NODE_ENV === 'production';

export const GOOGLE_ISO_CLIENT_ID = '';
export const GOOGLE_ANDROID_CLIENT_ID = '';
export const GOOGLE_CLIENT_ID = '';

export const FACEBOOK_APP_ID = '';

export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DATABASE_URL = ''; // @ NOT NEEDED FOR NOW
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const DEFAULT_ERROR_MESSAGE =
  'Oups, Looks like something went wrong, please try again later !';

export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000/graphql';
export const REACT_APP_CHAT_API_URL_WS =
  process.env.REACT_APP_CHAT_API_URL_WS || 'ws://localhost:4040/graphql';
export const REACT_APP_CHAT_API_URL_HTTP =
  process.env.REACT_APP_CHAT_API_URL_HTTP || 'http://localhost:4040/graphql';

export const REACT_APP_CLIENT_APP_URL = process.env.REACT_APP_CLIENT_APP_URL;

export const STRIPE_PUBLIC_API_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || '';

export const REACT_APP_TAWKTO_KEY = process.env.REACT_APP_TAWKTO_KEY || '';
export const REACT_APP_TAWKTO_PROPERTY_ID =
  process.env.REACT_APP_TAWKTO_PROPERTY_ID || '';

export const ENVIRONMENTS = {
  STAGING: 'STAGING',
  DEV: 'DEV',
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION'
};
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;
export const REACT_APP_GA4_ID = process.env.REACT_APP_GA4_ID;

export const sports = [
  {
    label: 'ski',
    value: 'ski',
    Icon: SkiIcon
  },
  {
    label: 'snowboard',
    value: 'snowboard',
    Icon: SnowboardIcon
  }
];

export const levels = [
  {
    label: 'new',
    value: 'BEGINNER',
    Icon: NewIcon
  },
  {
    label: 'intermediate',
    value: 'INTERMEDIATE',
    Icon: IntermediateIcon
  },
  {
    label: 'advanced',
    value: 'ADVANCED',
    Icon: AdvancedIcon
  }
];

export const languages = [
  {
    title: 'EN_UK',
    code: 'en-UK',
    Flag: UKFlag
  },
  {
    title: 'FR_FR',
    code: 'fr-FR',
    Flag: FranceFlag
  },
  {
    title: 'ES_ES',
    code: 'es-ES',
    Flag: SpainFlag
  },
  {
    title: 'IT_IT',
    code: 'it-IT',
    Flag: ItalianFlag
  },
  {
    title: 'ES_CT',
    code: 'es-CT',
    Flag: CatalanFlag
  },
  {
    title: 'DE_DE',
    code: 'de-DE',
    Flag: GermanFlag
  },
  {
    title: 'DUT_NL',
    code: 'dut-NL',
    Flag: DutchFlag
  },
  {
    title: 'AR_AR',
    code: 'ar-AR',
    Flag: ArabicFlag
  },
  {
    title: 'RU_RU',
    code: 'ru-RU',
    Flag: RussianFlag
  },
  {
    title: 'PT_POR',
    code: 'pt-POR',
    Flag: PortugueseFlag
  },
  {
    title: 'OTHER',
    code: 'other',
    Flag: WorldFlag
  }
];

export const INTERFACE_LANGUAGES = ['en-UK', 'fr-FR', 'es-ES'];

export const MAX_TIME = '17:00';
export const MIN_TIME = '08:00';

export const PASSCODE_RESTRICTED = process.env.REACT_APP_PASSCODE_RESTRICTED;
export const PASSCODE_FULL = process.env.REACT_APP_PASSCODE_FULL;
