/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import './Auth.scss';
import SkiAnimation from '../../SkiAnimation/SkiAnimation';
import Button from '../../Button/Button';
import { PASSCODE_RESTRICTED, PASSCODE_FULL } from '../../../config/constants';
import { Role, useAuthContext } from '../../../hooks/useAuth/Auth.provider';
import { useHistory } from 'react-router-dom';
import { logInWithEmailAndPassword } from '../../../config/firebase';
import { triggerGoogleTagEvent } from '../../../utils/triggerGoogleTagEvent';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { isLoggedIn, setIsLoggedIn, setRole } = useAuthContext();
  const history = useHistory();

  const login = () => {
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    logInWithEmailAndPassword(password)
      .then((role) => {
        triggerGoogleTagEvent('login', { email: email, role: role });
        if (role) {
          setIsLoggedIn(true);
          setRole(role);
          history.push('/documents');
        }
      })
      .catch((error) => {
        setPasswordError(true);
      });
  };

  return (
    <div className="auth">
      <div className="login-container">
        {/* login form for email and password */}
        <h1 className="login-title">Vimbu - Data Room</h1>
        <div className="login-input-container">
          {emailError && <p className="error">Please enter a valid email</p>}
          <input
            type="text"
            className="login-input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {passwordError && <p className="error">Incorrect password</p>}
          <input
            type="password"
            className="login-input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Button classNames={['login-button']} title="Login" onClick={login} />
      </div>
      <SkiAnimation />
    </div>
  );
};

export default Auth;
