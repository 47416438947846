import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FooterAnim from '../svg/footer';
import './SkiAnimation.scss';

const SkiAnimation: React.FC = (): JSX.Element => {
  return (
    <div className="ski-animation">
      <FooterAnim />
    </div>
  );
};

export default SkiAnimation;
